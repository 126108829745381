<template>
 <div>
    <v-row  no-gutters>
          <va-update v-if="$store.state.required_update" :system="system"/>
              <v-col cols="12" sm="12" class="pa-2 animate__animated"  v-for="(item, index) in $store.state.game_seq" :key="index" :class="getAnim(index)" >
              
                <va-two-card 
                    color="rescard"
                    icon="mdi-billiard"
                    :data="GET_RESULT(item)"
                    sup=""
                  />
              </v-col>
              <v-col cols="12" sm="12" class="pa-2 animate__animated">
                  <v-card rounded>
                    <v-spacer></v-spacer>
                      <div class="text-center pa-4">
                          Next Draw: {{$moment(NEXT_DRAW.date).format("MMMM DD")}}, {{NEXT_DRAW.time}}
                          <v-divider class="ma-2" />
                        <span style="font-size: 1.8em; letter-spacing: 3px;" class="text-center font-weight-bold text-gold" id="va-timer">--:--:--</span>
                     
                    </div>
                      <v-spacer></v-spacer>
                  </v-card>
              </v-col>
              <v-col cols="12" sm="6" class="pa-2 animate__animated">
                <v-btn color="secondary" dark block x-large @click="play('P2')">
                  <v-divider class="mr-4" color="gold"/> <span>Tumbok/Sahod</span><v-divider class="ml-4" color="gold"/>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="pa-2 animate__animated">
                <v-btn color="secondary" dark block x-large @click="play('P2-SAKLANG')">
                  <v-divider class="mr-4" color="gold"/> <span>SAKLANG</span><v-divider class="ml-4" color="gold"/>
                </v-btn>
              </v-col>
               <v-col cols="12" sm="6" class="pa-2 animate__animated">
                <v-btn color="secondary" dark block x-large @click="play('P3')">
                  <v-divider class="mr-4" color="gold"/> <span >STREAMLINE </span><v-divider class="ml-4" color="gold"/>
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" class="pa-2 animate__animated">
                <v-btn color="secondary" dark block x-large @click="play('P2-POMPYANG')">
                  <v-divider class="mr-4" color="gold"/> <span>POMPYANG</span><v-divider class="ml-4" color="gold"/>
                </v-btn>
              </v-col>
             <!-- <v-col cols="12" sm="6" class="pa-2 animate__animated">
                <v-btn color="secondary" dark block x-large @click="play('P4')">
                  <v-divider class="mr-4" color="gold"/> <span>PLAY PICK 4 </span><v-divider class="ml-4" color="gold"/>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="pa-2 animate__animated" >
                <v-btn color="secondary" dark block x-large @click="play('P5')">
                  <v-divider class="mr-4" color="gold"/> <span>PLAY PICK 5 </span><v-divider class="ml-4" color="gold"/>
                </v-btn>
              </v-col> -->
       
              <v-col cols="12"  class="pa-2">
              
                <v-card>
                  <v-toolbar dense color="secondary" dark><span class="text-h6">Winning Numbers</span> </v-toolbar>
                  <table class="print_table md-font" >
                      <tr>
                        <th class="text-center pa-2">GAME</th>
                          <th class="text-center">DRAW</th>
                          <th class="text-center">RESULT</th>
                      </tr>
                      <tr v-for="(item, index) in RESULTS" :key="index">
                          <td class="text-center font-weight-bold" :class="'row-' +IS_ODD(index)"> {{item.game}}  </td>
                          <td :class="'row-' +IS_ODD(index)">{{$moment(item.date).format('MMM DD') + ' ' + item.time}}</td>
                          <td class="text-left" :class="'row-' +IS_ODD(index)">
                            <template v-if="item.game == 'STL' || item.game == '2D'">
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[0]}}</v-btn>  
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[1]}}</v-btn>
                              </template>
                              <template v-else-if="item.game == '3D'">
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[0]}}</v-btn>  
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[1]}}</v-btn>
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[2]}}</v-btn>
                              </template>
                              <template v-else-if="item.game == '4D'">
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[0]}}</v-btn>  
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[1]}}</v-btn>
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[2]}}</v-btn>
                                <v-btn x-small class="ma-1" fab elevation="0" color="secondary">{{Nums(item.combination)[3]}}</v-btn>
                              </template>
                          </td>
                      </tr>
                  </table>
                </v-card>
                
              </v-col>
 
        <!-- <v-col cols="6"  class="pa-2 mt-5">
            <v-btn small  block @click="$router.push('/results')"><v-icon  color="info" left>mdi-clipboard-list-outline</v-icon> RESULTS</v-btn>
        </v-col>
          <v-col cols="6"  class="pa-2 mt-5">
            <v-btn  small @click="how=true" block><v-icon  left color="info">mdi-account-question</v-icon> HOW TO PLAY</v-btn>
        </v-col> -->

    </v-row>
    <va-howto :show="how" @DialogEvent="hEvent"/>
  </div>
</template>
<script>
//import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
   // mixins: [API_MIXINS],
    data: () => ({
      
      watch: false,
      DRAWINTERVAL: null,
      //next_draw:"",
      how:false,
      result: {},
        system: {},
    }),
    created() {
      this.PING()
        this.setData()
    },
     mounted(){
         this.setDrawer(false)
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.SYSTEM_UPDATE()
          this.GET_RESULTS()
          this.GET_OPEN_GAMES("STL")
          if(this.OPEN_DRAWS.length) {
            setTimeout(()=>{

              let next = this.$moment(this.NEXT_DRAW.date + " " + this.NEXT_DRAW.time)
              let duration = this.$moment.duration(next.diff(this.$moment()));
            
              var element = document.getElementById("va-timer");
              element.innerHTML =  this.$ZeroPad(duration.hours(),2) +":"+this.$ZeroPad(duration.minutes(),2)+":"+this.$ZeroPad(duration.seconds(),2);
            },3000)
        
          this.DRAWINTERVAL = setInterval(()=>{
            let next = this.$moment(this.NEXT_DRAW.date + " " + this.NEXT_DRAW.time)
              let duration = this.$moment.duration(next.diff(this.$moment()));
            
              var element = document.getElementById("va-timer");
              element.innerHTML =  this.$ZeroPad(duration.hours(),2) +"<small>h</small>:"+this.$ZeroPad(duration.minutes(),2)+"<small>m</small>:"+this.$ZeroPad(duration.seconds(),2)+"<small>s</small>";
          },1000)
          }
        } 
        
    },
    beforeDestroy(){
       if(this.DRAWINTERVAL != null) { clearInterval(this.DRAWINTERVAL)}
       return true
    },
    computed:{
     
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      RESULTS(){
        let limitedArray = this.$store.state.results.slice(0, 6);
        return  limitedArray
      },
      OPEN_DRAWS() {
        return this.$store.state.open_2d_draws.length > 0 ? this.$store.state.open_2d_draws:[]
       },
       RESULT2D() {
         return this.GET_RESULT("2D")
      },
      item_count() {
        return this.$store.state.tickets.length.toString()
      },
      item_count_total() {
          var count = this.$store.state.tickets.reduce((res,item)=>res += this.$IsNum(item.amount), 0)
          return count.toString()
      },
      // OPEN_DRAWS() {
      //  return this.$store.state.open_draws
      // },
       NEXT_DRAW() {
        if(this.OPEN_DRAWS.length>0) {
          return this.OPEN_DRAWS[0]
        } else {
          return {date: this.$moment().format("YYYY-MM-DD"), time: "12:00 AM"}
        }
        
      }
    
    },
    methods: {
      ...mapMutations(['setDrawer', 
      'setAlertColor', 'setAlertText', 
      'setLoggedIn', 'setOverlayLoading',
       'setOverlayLoadingText', 'setOpenMachine',
       'setWatchDraw', 'setLiveDrawStatus', 'setPick']),
      getAnim(index) {
        let odd = index % 2 !== 0;
        if(odd) {
          return "animate__fadeInRight"
        } else {
          return "animate__fadeInLeft"
        }
      },
      play(pick){
        this.setPick(pick)
        this.$router.push("/play-stl")
      },
      hEvent(){
        this.how= false
      },
      GET_LABEL(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
              
                data =  this.GAMENAME(results[i].game) + " - "  +  results[i].time  + " DRAW"
                break
            } 
          }
       
          return data
      },
       GET_RESULT(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
               var nums = results[i].combination.split(" - ")
                results[i].nums = nums
                data =  results[i]
                break
            } 
          }
       
          return data
      },
      get_system_update() {
            //this.setRequiredUpdate(false)
            this.$http.get("system_update").then(response => {
              if(response.data.status) {
                 this.system = response.data.system
              }
               }).catch(e => {
                console.log(e.data)
            })
        },
        Nums(combinations){
         return  combinations.split('-')
      },
    
        setData() {
          this.CHECK_BALANCE(this.user.id)
          var param = {}
          param.account = this.user.id
          param.method = "per_account"
          param.from_date =  this.$moment().format("YYYY-MM-DD")
          this.GET_TICKETS(param)
          this.GET_RESULTS(param)
        
          setTimeout(()=>{this.get_system_update()}, 800)
      },
    },
  }
</script>